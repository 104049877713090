import React from "react";
import "./ContactPage.scss";
import { ContactPageData } from "../../Assets/Data/constants";
import EmailIcon from "../../Assets/email.svg";
import PhoneIcon from "../../Assets/call.svg";
import AddressIcon from "../../Assets/location.svg";

const ContactPage = () => {
	return (
		<div className="ContactPageContainer">
			<div className="item">
				<div className="details">
					<h1 className="title">Contact Us</h1>

					<h1 className="subtitle">Question? Get in touch!</h1>
					<div className="ContactItemsContainer">
						<div className="contactItem">
							<img src={EmailIcon} alt="" />
							<div>
								<h3>Email</h3>
								<p>{ContactPageData.email}</p>
							</div>
						</div>
						<div className="contactItem">
							<img src={PhoneIcon} alt="" />
							<div>
								<h3>Call Us</h3>
								<p>{ContactPageData.phone}</p>
							</div>
						</div>
						<div className="contactItem">
							<img src={AddressIcon} alt="" />
							<div>
								<h3>Office Address</h3>
								<p>{ContactPageData.address}</p>
							</div>
						</div>
					</div>
				</div>
				<form action="">
					<div className="InputFields">
						<input type="text" placeholder="Name" />
					</div>
					<div className="InputFields doubleField">
						<input type="text" placeholder="Phone" />
						<input type="email" placeholder="Email" />
					</div>
					<div className="InputFields doubleField">
						<input type="text" placeholder="Business Name" />
						<input type="text" placeholder="Business Category" />
					</div>
					<div className="InputFields">
						<textarea rows={5} placeholder="Need of a solutions"></textarea>
					</div>
					<button className="contactBtn" type="submit">
						Submit
					</button>
				</form>
			</div>
		</div>
	);
};

export default ContactPage;
