import React from "react";
import "./GetStarted.scss";
import { GetStartedData } from "../../Assets/Data/constants";

const GetStarted = () => {
	return (
		<div id="getStarted" className="GetStartedContainer">
			<div className="item">
				<div className="details">
					<h1>{GetStartedData && GetStartedData.title}</h1>
					<img src={GetStartedData && GetStartedData.img} alt="" />
				</div>
				<form action="">
					<div className="InputFields">
						<label htmlFor="Name">Name</label>
						<input type="text" />
					</div>
					<div className="InputFields">
						<label htmlFor="Name">Email</label>
						<input type="email" />
					</div>
					<div className="InputFields">
						<label htmlFor="Name">Message</label>
						<textarea rows={5}></textarea>
					</div>
					<button className="contactBtn" type="submit">
						Request Demo
					</button>
				</form>
			</div>
		</div>
	);
};

export default GetStarted;
